@font-face {
  font-family: 'Tangerine';
  src: local('Tangerine'), 
      url('./fonts/Tangerine-Regular.woff2') format('woff2'),
      url('./fonts/Tangerine-Regular.woff') format('woff'),
      url('./fonts/Tangerine-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media (prefers-color-scheme: dark) {
  :root {
    --text_white: #1e2432;
    --bg_main: #101027;
    --text_light: #fff;
    --text_med: #53627c;
    --text_dark: #1e2432;
    --red: #ff1e42;
    --darkred: #530606;
    --orange: #2c839e;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --text_white: #1e2432;
    --bg_main: #101027;
    --text_light: #fff;
    --text_med: #53627c;
    --text_dark: #1e2432;
    --red: #ff1e42;
    --darkred: #530606;
    --orange: #2c839e;
  }
}

body {
  margin: 0;
  background-image: url("https://domainofdreams.s3.ca-central-1.amazonaws.com/Adjust+these+pieces.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  font-family: "Tangerine";
}

.fairytale-form-note {
  font-size: 2.19rem; /* 35px */
}

div {
  display: block;
  text-align: center;
  padding-top: 0.3rem; /*10px converted to rem *?
  font-size: 2rem; /* 32px converted to rem */
  font-weight: bold;
  letter-spacing: 0.02em;
  color: var(--text_dark);
}

label {
  cursor: pointer;
  color: var(--text_white);
  display: block;
  padding: 0;
  margin: 0.19rem; /* 3px */
  font-size: 2.5rem; /* 40px */
}

.logo {
  text-align: center;
  font-weight: bold;
  font-size: 3.75rem; /* 60px */
  letter-spacing: 0.02em;
  text-decoration: none;
  color: inherit;
}

input[type="text"], textarea {
  border: 0.125rem solid #8B4513; /* 2px */
  background-color: #FAEBD7;
  margin: 0.63rem; /* 10px */
  box-shadow: 0.063rem 0.063rem 0.313rem rgba(0, 0, 0, 0.2); /* 1px 1px 5px */
  transition: border-color 0.3s;
  color: var(--text_dark);
  font-family: "Garamond";
  font-size: 1.5rem; /* 24px */
  letter-spacing: 0.02em;
}

input[type="text"] {
  width: 30%;
  padding: 0.31rem 1.25rem; /* 5px 20px */
}

textarea {
  width: 100%;
  max-width: 60rem;
  height: 5rem;
  padding: 0.63rem; /* 10px */
}

input[type="text"]:focus, textarea:focus {
  border-color: #A0522D;
}

.button-fairytale {
  background-color: #7b4397;
  border: none;
  color: white;
  padding: 1.25rem 2.19rem; /* 20px 35px */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2.19rem; /* 35px */
  margin: 0.25rem 0.13rem; /* 4px 2px */
  cursor: pointer;
  border-radius: 0.94rem; /* 15px */
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0,0,0,0.2); /* 4px 8px */
  transition: all 0.3s;
  line-height: 1.2;
  box-sizing: border-box;
  min-width: 23rem;
  font-family: "Tangerine";
}
  
.button-fairytale:hover {
  background-color: #6a2c70; /* slightly darker purple /
  box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,0.3); / 8px 16px */
  transform: scale(1.05);
}
  
.story-text-container {
  width: 75%;
  margin: auto; /* Centers the div */
}
  
.story-text-container p {
  font-size: 2rem; /* 32px /
  line-height: 2; / Increase line spacing as needed */
}
  
.text-area-story {
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
  
.nav-and-text {
  display: flex;
  align-items: center; /* Aligns text and buttons vertically */
}
  
.nav-button {
  margin: 0 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; /* 24px */
  transition: transform 0.3s ease;
}
  
.nav-button:hover {
  transform: translateY(-0.19rem); /* 3px */
}
  
.nav-button:disabled {
  color: #808080; /* A darker shade of gray for better visibility /
  cursor: not-allowed; / shows a different cursor to indicate the button is disabled */
}
  
.initial-letter {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 5rem; /* 80px */
  line-height: 0;
  color: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  display: inline;
}
  
.story-text-div-visible {
  font-size: 2.81rem; /* 45px */
  line-height: 1.6;
}
  
.hidden {
  display: none;
}
  
.App-icon {
  height: 10vmin; /* Adjust this value as needed */
  padding-left: 0.4vmin;
}
  
.header-content {
  display: flex;
  align-items: center; /* Align vertically */
  justify-content: center; /* Center content horizontally */
}
  
.image-container img {
  max-height: 50vh; /* Maximum height is 100% of the viewport height */
  max-width: 100vw; /* Maximum width is 100% of the viewport width */
  object-fit: contain; /* Resize the image to fit while maintaining aspect ratio */
}

/* Media Queries for Phone Screens */
@media only screen and (max-width: 600px) {

  body {
      font-size: 0.75rem; /* Further reduced */
      background-image: url("https://domainofdreams.s3.ca-central-1.amazonaws.com/MobilePhoneBackground.png");
      background-size: 100% auto; /* Width is 100%, height auto */
      background-position: center top; /* Center the image horizontally */
  }

  .fairytale-form-note {
      font-size: 1.25rem; /* Significantly smaller */
  }

  div {
      padding-top: 0.3rem; /* Reduced */
      font-size: 1.25rem; /* Smaller */
  }

  label {
      margin: 0.1rem; /* Reduced */
      font-size: 1.5rem; /* Smaller */
  }

  .logo {
      font-size: 2.5rem; /* Smaller */
  }

  input[type="text"], textarea {
      font-size: 1rem; /* Reduced */
      padding: 0.2rem 0.8rem; /* Reduced */
      margin: 0.3rem;
  }

  .button-fairytale {
      font-size: 1.25rem; /* Smaller */
      padding: 0.8rem 1.5rem; /* Reduced */
      border-radius: 0.6rem; /* Reduced */
      min-width: 13rem;
  }

  .story-text-container p {
      font-size: 1.15rem; /* Smaller */
  }

  .nav-button {
      font-size: 0.5rem; /* Reduced */
      margin: 0.5rem;
  }

  .initial-letter {
      font-size: 3rem; /* Smaller */
  }

  .story-text-div-visible {
      font-size: 1.15rem; /* Smaller */
  }

  .App-icon {
      height: 14vmin; /* Reduced */
  }

  .text-area-story {
    width: 95%;
  }

  .story-text-container {
    width: 85%;
  }

  textarea {
    height: 3rem;
    max-width: 30rem;
    width: 50%;
  }

  .image-container img {
    max-height: 30vh;

  }

  /* Add any additional specific styling changes for phone screens as needed */
}
