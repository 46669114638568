
@keyframes burnoutEffect-left-to-right {
	from {
	  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
	to {
	  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
	}
	0% {
	  opacity: 1;
	  transform: scale(1);
	  color: black;
	  text-shadow: none;
	}
	30% {
	  color: darkorange;
	  text-shadow: 0 0 10px red, 0 0 20px yellow;
	}
	70% {
	  color: red;
	  text-shadow: 0 0 15px darkred, 0 0 30px orange;
	}
	100% {
	  opacity: 0;
	  transform: scale(0.9) translateY(-10px);
	  color: darkred;
	  text-shadow: 0 0 20px black, 0 0 40px maroon;
	}
  }
  
  @keyframes burnoutEffect-right-to-left {
	from {
	  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
	}
	to {
	  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
	}
	0% {
	  opacity: 1;
	  transform: scale(1);
	  color: black;
	  text-shadow: none;
	}
	30% {
	  color: darkorange;
	  text-shadow: 0 0 10px red, 0 0 20px yellow;
	}
	70% {
	  color: red;
	  text-shadow: 0 0 15px darkred, 0 0 30px orange;
	}
	100% {
	  opacity: 0;
	  transform: scale(0.9) translateY(-10px);
	  color: darkred;
	  text-shadow: 0 0 20px black, 0 0 40px maroon;
	}
  }
  
  .burnout-left-to-right {
	animation: burnoutEffect-left-to-right 1s ease-out forwards;
  }
  
  .burnout-right-to-left {
	animation: burnoutEffect-right-to-left 1s ease-out forwards;
  }
  
  @keyframes fadeIn-left-to-right {
	from {
	  opacity: 0;
	  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
	  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
	}
	to {
	  opacity: 1;
	  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
	  text-shadow: none;
	}
  }
  
  @keyframes fadeIn-right-to-left {
	from {
	  opacity: 0;
	  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
	  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
	}
	to {
	  opacity: 1;
	  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	  text-shadow: none;
	}
  }
  
  .fadeIn-left-to-right {
	animation: fadeIn-left-to-right 1s ease-in forwards;
  }
  
  .fadeIn-right-to-left {
	animation: fadeIn-right-to-left 1s ease-in forwards;
  }

.fancy-border-video.unloaded {
	/* border: 5px solid transparent; Make border transparent initially */
    opacity: 0; /* Make video fully transparent initially */
	display: none;
	transition: opacity 0.5s ease, transform 0.5s ease;
}

.fancy-border-video {
	border-width: 20px 15px; /* Top and bottom borders are thicker */
    border-style: solid;
    border-color: transparent;
    border-image-source: url('https://domainofdreams.s3.ca-central-1.amazonaws.com/OrnateBorder.png');
    border-image-slice: 10%;
    border-image-repeat: round;
    position: relative;
    overflow: hidden; /* To contain pseudo-elements */

	height: 60%;
	min-height: 60%;
	width: 40%;
	min-width: 40%;
    /* width: auto; Maintain aspect ratio */
    margin: 20px auto; /* Center the video */
    display: block; /* To apply margin auto */
}



.invisible-class {
    visibility: hidden; /* or 'opacity: 0;' for a fade-out effect */
	display: none;
}

@media only screen and (max-width: 600px) {
	.fancy-border-video {
		border-width: 10px 7px;
		height: 80%;
		min-height: 80%;
		width: 60%;
		min-width: 60%;
	}

}